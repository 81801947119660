<template>
  <component :is="modal" />
</template>

<script>
const TrocarRevenda = () => import("@/components/modal/TrocarRevenda.vue");
export default {
  name: "Modal",
  components: {
    TrocarRevenda,
  },
  computed: {
    modal() {
      return this.$store.state.modal;
    },
  },
};
</script>
